div.menu-icon {
    position: fixed;
    top: 2px;
    left: 1rem;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 0.5rem;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
        top: 6px;
    }

    svg {
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
    }
}

div.top-cart-icon {
    position: fixed;
    top: 2px;
    //left: 1rem;
    right: 4rem;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.08);
    padding: 0.5rem;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
        top: 6px;
        right: 6rem;
    }

    a {
        position: relative;
    }

    svg {
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
    }

    .cart-count {
        position: absolute;
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 0rem 0.2rem;
        line-height: 1;
        left: 1rem;

        @include media-breakpoint-up(sm) {}
    }
}

div.mobile-side-menu-container {
    display: block;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

div.dash__board__layout {

    //border: 1px solid red;
    //margin-left: 0px;
    //margin-right: 0px;
    //display: flex;
    div.side_panel {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }
}

div.side_panel {
    height: 100vh;
    background-image: linear-gradient(90deg, #141e30, #243b55);
    //padding: 1rem;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    width: 250px;
    position: fixed;
    z-index: 9;

    .display__name {
        background-color: $color-dark-gray;
        color: white;
        margin: 0px;
        padding: 2rem 1rem;

        h4 {
            color: white;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
        }
    }

    .admin__text {
        margin-bottom: 1rem;
        color: red;
        font-weight: 700;
        font-size: 1.2rem;
    }
}

div.top_bar {
    padding-left: 0px;
    position: fixed;
    width: 100%;
    z-index: 8;

    @include media-breakpoint-up(sm) {
        padding-left: 250px;
    }

    a:hover {
        text-decoration: none;
    }

    .title {
        //margin-top: 0px;
        //margin-bottom: 2rem;
        padding: 1rem;
        color: white;
        max-width: 1025px;
        margin: auto;

        a {
            color: white;
        }
    }
}

div.main_panel {
    //flex: 1 0 75%;
    max-width: 100%;
    padding-left: 0px;
    padding-top: 4rem;

    @include media-breakpoint-up(sm) {
        padding-left: 250px;
    }

    .main_panel_inner {
        padding: 1rem;
        max-width: 1025px;
        margin: auto;

        .portal-title {
            margin-bottom: 1rem;
            font-weight: $weight-bold;
            font-size: 1.3rem;
        }
    }
}

.member-page div.container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

section.sideBarSection {
    //margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .menu-item,
    .menu-item a {
        color: white;
    }

    .menu-item {
        margin-top: 1.5rem;

        .menu-item-title {
            margin-bottom: 1rem;
        }

        .menu-item-sub {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 8px;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            border-radius: 6px;

            &.selected {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }

            &.admin.sub{
                pointer-events : none;
                a{
                    color: gray;
                }
            }

            a,
            span.button {
                padding-left: 0.3rem;

                &:hover {
                    text-decoration: none;
                }
            }

            svg {
                margin-top: -3px;
            }
        }
    }

    .menu-item:first-child {
        margin-top: 0rem;
    }
}

div.dash__board__layout.close,
div.mobile-side-menu-container.close {
    div.side_panel {
        max-width: 0px;
        padding: 0rem;
    }

    div.main_panel {
        padding-left: 0rem;
    }
}