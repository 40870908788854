.tableList {
    width: 100%;
    //margin: 1.5rem 0;
    //margin-top: 0.5rem;
  
    th {
      background-color: #ddd;
      border-bottom: solid 1px #999;
      padding: 0.4rem;
    }
  
    td {
      padding: 0.2rem 0.4rem;
    }

    @include media-breakpoint-down(sm){
      font-size: 0.9rem;
    }
    a:link, a:visited, a:hover, a:active { text-decoration: none; }
  }
  
  .filter {
    .form-control {
      width: auto;
    }
}

.tableList tbody tr:nth-child(2n) {
    background-color: #f0f0f0;
}

.tableList tr th:nth-child(2), .tableList tr td:nth-child(2){
    //max-width: 50px;
    display: none;
    @include media-breakpoint-up(sm){
      display: table-cell;
    }
}

.tableList tr th:nth-child(3), .tableList tr td:nth-child(3){
    //max-width: 50px;
    display: none;
    @include media-breakpoint-up(sm){
      display: table-cell;
    }
}

.tableList tr td:nth-child(4){
    //max-width: 350px;
}

.tableList tr td:nth-child(7){
    //max-width: 70px;
}

// .tableList thead{
//     position: sticky;
//     top: 49px;
// }